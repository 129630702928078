(function ($, window, document, undefined) {

    let domain = document.location.hostname.replace(/^www\./, '');
    let consents = 
    [
        {
            name: 'necessary',
            necessary: true,
            allowed: true,
            cookies: [ { key: 'CookieConsent', path: '/', domain: domain } ]
        },
        {
            name: 'statistics',
            necessary: false,
            allowed: false,
            cookies: [ { key: '_ga' ,   path: '/', domain: domain }, 
                       { key: '_gid',   path: '/', domain: domain }, 
                       { key: '_gat',   path: '/', domain: domain },
                       { key: '__utma', path: '/', domain: domain },
                       { key: '__utmb', path: '/', domain: domain },
                       { key: '__utmc', path: '/', domain: domain },
                       { key: '__utmt', path: '/', domain: domain }, 
                       { key: '__utmv', path: '/', domain: domain } ]
        },
        {
            name: 'marketing',
            necessary: false,
            allowed: false,
            cookies: [ { key: 'IDE', path: '/', domain: domain },
                       { key: 'pagead/1p-user-list/#', path: '/', domain: domain },
                       { key: 'test_cookie', path: '/', domain: domain },
                       { key: 'GPS', path: '/', domain: domain },
                       { key: 'VISITOR_INFO1_LIVE', path: '/', domain: domain },
                       { key: 'YSC', path: '/', domain: domain } ]
        }
    ];

    let texts = 
    {
        'de': 
        {
            banner: `<h3>Diese Webseite verwendet Cookies</h3>
            <span>Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.</span>`,

            acceptAllCookies: 'Alle Cookies zulassen',
            acceptSelectedCookies: 'Auswahl erlauben',

            necessary: 'Notwendig',
            marketing: 'Marketing',
            statistics: 'Statistik'
        },

        'en': 
        {
            banner:  `<h3>This website uses cookies</h3>
            <span>We use cookies on this website. Some are essential, some help us to enhance the user experience.</span>`,

            acceptAllCookies: 'Allow all cookies',
            acceptSelectedCookies: 'Allow selection',

            necessary: 'Necessary',
            marketing: 'Marketing',
            statistics: 'Statistics'
        }
    };

    $(function() {
        let settings;
        let getAllDocumentCookies = function () {
            let pairs = document.cookie.split(';');
            let cookies = [];

            for(let i = 0; i < pairs.length; i++) {
                let pair = pairs[i].split('=');
                cookies.push((pair[0] + '').trim());
            };

            return cookies;
        };

        let getDocumentCookie = function(key) {
            let decoded = decodeURIComponent(document.cookie);
            let cookies = decoded.split(';');

            for(let i = 0; i < cookies.length; i++) {
                let current = cookies[i];

                let pair = current.split('=');
                if(pair[0].trim() === key.trim())
                    return pair[1];
            };

            return undefined;
        };

        let hasDocumentCookie = function(key) {
            return getDocumentCookie(key) !== undefined;
        };

        let addDocumentCookie = function (key, value, expire = new Date(), path = '/') {
            document.cookie = key + "=" + value + ";" + "expires="+ expire.toUTCString() +"; path=" + path;
        };

        let deleteDocumentCookie = function (key, path = '/', domain = false) {
            if(!domain) {
                domain = document.location.hostname.replace(/^www\./, '');
            }

            document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain="+domain+"; path="+path;
        };

        let purge = function () {
            let allowedCookieKeys = consents
                .filter(function(el) { return el.allowed; })
                .map(function(el) { return el.key; });
            
            let cookies = getAllDocumentCookies(); 
            for(let i = 0; i < cookies.length; i++) {
                let current = cookies[i];
                
                if(!allowedCookieKeys.includes(current))
                    deleteDocumentCookie(current, '/');
            };
        };

        let showCookieBanner = function () {
            let text = settings.language === 'de' ? texts['en'] : texts['en'];
            let container = $('<div>')
                .addClass('cookieBannerContainer')
                .css('background', settings.background)
                .css('box-shadow',  settings.boxShadow);

            container.append($('<div>').addClass('cookieBannerTextContainer').append(
                text['banner']
            )).css('color', settings.textColor);

            let consentContainer = $('<div>').addClass('cookieBannerConsentContainer');
            let checkBoxContainer = $('<div>');
            for(let i = 0; i < consents.length; i++) {
                checkBoxContainer.append(
                    $('<input>')
                        .attr('type', 'checkbox')
                        .attr('id', consents[i].name)
                        .prop('checked', consents[i].necessary)
                        .prop('disabled', consents[i].necessary)
                        .on('change', function() {
                            consents[i].allowed = !consents[i].allowed;
                        }),
                    $('<label>')
                        .attr('for', consents[i].name)
                        .html(text[consents[i].name])
                        .css('color', settings.textColor)

                )
            }

            consentContainer.append(checkBoxContainer);
            container.append(consentContainer);

            container.append($('<div>').addClass('cookieBannerButtonWrapper').append(
                $('<button>').html(text['acceptAllCookies'])
                .css('color', settings.buttonForegroundColor)
                .css('background', settings.buttonBackgroundColor)
                .on('click', function() {
                    let date = new Date();
                    date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                    
                    addDocumentCookie("CookieConsent", "all", date);

                    $('.cookieBannerContainer').css('display', 'none');
                    settings.accepted();
                }),
                $('<button>').html(text['acceptSelectedCookies'])
                    .css('color', settings.buttonForegroundColor)
                    .css('background', settings.buttonBackgroundColor)
                    .on('click', function() {
                        let consent = consents
                            .filter(function(el) { return el.allowed; })
                            .map(function(el) { return el.name; })
                            .join(',');
                        
                        let date = new Date();
                        date.setTime(date.getTime() + (12 * 60 * 60 * 1000));
                        
                        addDocumentCookie("CookieConsent", consent, date);

                        $('.cookieBannerContainer').css('display', 'none');
                        settings.accepted();
                    })
            ));

            $('body').append(container);
        };

        return $.extend({
            cookies: function (opts) {
                settings = $.extend({
                    init: function() {},
                    accepted: function() {},

                    background: 'green',
                    boxShadow: 'none',
                    buttonSide: 'left',
                    language: 'en',

                    textColor: '#fff',
                    highlightColor: '#fff',
                    highlightTextColor: '#000'
                }, opts);

                if(!hasDocumentCookie('CookieConsent')) {
                    settings.init();
                    purge();
                    showCookieBanner();
                } else {
                    let value = getDocumentCookie('CookieConsent');
                    if(value === 'all') {
                        for(let i = 0; i < consents.length; i++)
                            consents[i].allowed = true;
                    } else {
                        let parts = value.split(',').map(function(el) { return el.trim(); });
                        for(let i = 0; i < consents.length; i++) {
                            if(parts.includes(consents[i].name.trim())) 
                                consents[i].allowed = true;
                        };
                    }
        
                    purge();
                }

                return this;
            }
        })
    });

    if(typeof module !== 'undefined')
        module.exports = $;
})(jQuery, window, document);