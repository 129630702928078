(function ($, window, document, undefined) {
    var pluginName = "upload",
        defaults = {
            url: 'upload',
            uploadButton: 'button',
            multiple: false,
            onUploadComplete: false,
            onUploadCanceled: false,
            onUploadsComplete: false,
            beforeUpload: false,
            onMultipleFilesForSingleUpload: function (amount) {
                alert('you have dropped ' + amount + ' files, but you can only upload one!');
            },
            onWrongMimeType: function () {
                alert('you are trying to upload the wrong file type!');
            },
            allowedMimeTypes: '*', //can be an array!,
            progressBar: false
        };

    function Plugin(element, options) {
        this.element = $(element);
        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Plugin.prototype = {
        init: function () {
            var self = this;
            self.initChangeHandlers();
            self.initClickHandlers();
            self.initDropHandlers();
        },

        initChangeHandlers: function () {
            var self = this;
            self.element.find('input').on('change', function (e) {
                self.upload(e.target.files);
            });
        },

        initClickHandlers: function () {
            var self = this;

            self.fileInput = self.element.find('input[type="file"]');
            if (self.options.allowedMimeTypes != '*') {
                if ($.isArray(self.options.allowedMimeTypes)) {
                    self.fileInput.attr('accept', self.options.allowedMimeTypes.join('|'));
                } else {
                    self.fileInput.attr('accept', self.options.allowedMimeTypes);
                }
            }
            if (self.options.multiple) {
                self.fileInput.prop('multiple', 'multiple');
            } else {
                self.fileInput.removeProp('multiple');
            }

            self.element.find(self.options.uploadButton).on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                self.fileInput.trigger('click');
            });

        },

        checkMimeTypeForFile: function (mimeType, file) {
            var regExp = new RegExp(mimeType.split('*').join('.*'));
            return regExp.test(file.type);
        },

        initDropHandlers: function () {
            var self = this;

            self.element.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
                e.preventDefault();
                e.stopPropagation();
            }).on('dragover dragenter', function (e) {
                self.element.addClass('dragOver');
            }).on('dragleave dragend drop', function (e) {
                self.element.removeClass('dragOver');
            }).on('drop', function (e) {
                var droppedFiles = e.originalEvent.dataTransfer.files;
                var good = true;
                if (!self.options.multiple && droppedFiles.length > 1) {
                    self.options.onMultipleFilesForSingleUpload(droppedFiles.length);
                    good = false;
                }
                if (good) {
                    self.upload(droppedFiles);
                }
            });
        },

        updateProgress: function (e) {
            var self = this;
            if (self.options.progressBar) {
                $(self.options.progress).show();
            }
            if (self.options.progressBar) {
                $(self.options.progressBar).css('width', Math.ceil(100 / e.total * e.loaded) + '%');
            }
        },

        upload: function (files) {
            var self = this;
            //todo: fragen, ob wirklich hochladen #settings.confirm
            if (navigator.userAgent.indexOf('Edge') !== -1 && self.options.progressBar) {
                $(self.options.progressBar).append(
                    $('<img>').attr('src', BASEURL + 'img/loading.gif')
                );
            }
            if (files !== null && files.length > 0) {
                if (self.options.allowedMimeTypes != '*') {
                    var good = true;
                    $.each(files, function (index, file) {
                        var found = false;
                        if ($.isArray(self.options.allowedMimeTypes)) {
                            $.each(self.options.allowedMimeTypes, function (index, mimeType) {
                                if (self.checkMimeTypeForFile(mimeType, file)) {
                                    found = true;
                                    return false;
                                }
                            });
                        } else {
                            if (self.checkMimeTypeForFile(self.options.allowedMimeTypes, file)) {
                                found = true;
                            }
                        }
                        if (!found) {
                            good = false;
                            return false;
                        }
                    });
                    if (!good) {
                        self.options.onWrongMimeType();
                        return;
                    }
                }
                if (self.options.beforeUpload) {
                    self.options.beforeUpload();
                }

                var fileBuffer = [];

                // append the file list to an array
                Array.prototype.push.apply(fileBuffer, files); // <-- here

                // And now you may manipulated the result as required

                // shift an item off the array
                var file = fileBuffer.shift(0, 1);
                //var file = files.pop();
                var xhr = new XMLHttpRequest();
                xhr.open('POST', BASEURL + self.options.url, true);
                if (file) {
                    xhr.setRequestHeader('X-FILENAME', file.name);

                    if (navigator.userAgent.indexOf('Edge') === -1) {
                        xhr.upload.addEventListener('progress', function (e) {
                            self.updateProgress(e);
                        }, false);
                    }
                    xhr.onreadystatechange = function (e) {
                        if (e.target.readyState == 4) {
                            if (self.options.onUploadComplete) {
                                self.options.onUploadComplete(JSON.parse(xhr.responseText));
                            }
                            if (fileBuffer.length > 0) {
                                self.upload(fileBuffer);
                            } else {
                                if (self.options.onUploadsComplete) {
                                    self.options.onUploadsComplete(JSON.parse(xhr.responseText));
                                }
                            }
                        }
                    };
                    xhr.send(file);
                }
            } else {
                if (self.options.uploadCanceled) {
                    self.options.uploadCanceled();
                }
            }
        }
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName,
                    new Plugin(this, options));
            }
        });
    };

    module.exports = $;
})
(jQuery, window, document);