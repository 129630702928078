require('../../vendor/sogobiz/framework/src/js/modules/upload');
require('../../vendor/sogobiz/framework/src/js/modules/trans');
require('./modules/cookies');

let navigation;

import 'flatpickr';

$(function () {
    let Navigation = require('./modules/Navigation');
    let gender = $('input[name="gender"]').val();
    let attachments = JSON.parse($('input[name="attachments"]').val());
    let femaleLocations = [];
    let maleLocations = [];
    new Navigation();


    // info: Wiederherstellen der gewählten Körperstellen
    let locations = JSON.parse($('input[name="locations"]').val());
    if (gender === 'female') {
        femaleLocations = locations;
    } else {
        maleLocations = locations;
    }
    $.each(locations, function (index, location) {
        let $location = $('#' + gender + ' #' + location);
        $location.addClass((gender === 'female' ? 'f' : 'm') + '-active');
    });

    (function () {
        let language = $('.jsLanguageSwitcher.active').data('language');
        $.cookies({
            language: language,
            init: function () {
            },
            accepted: function () {
                $('.nav-container').css('top', 'unset');
            },

            background: '#fff',
            boxShadow: 'none',
            buttonSide: 'left',
            textColor: '#0c0c0c',

            buttonBackgroundColor: '#fff',
            buttonForegroundColor: '#0c0c0c',
        });
    })();

    $('ul.tabs li').on('click', function () {
        let tabID = $(this).attr('data-tab');
        gender = tabID;
        $('input[name="gender"]').val(gender);
        $('ul.tabs li').removeClass('current');
        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tabID).addClass('current');
        if (gender === 'female') {
            $('input[name="locations"]').val(JSON.stringify(femaleLocations));
        } else {
            $('input[name="locations"]').val(JSON.stringify(maleLocations));
        }
    });

    $('.circle').on('click', function () {
        if (gender === 'female') {
            if ($(this).hasClass('f-active')) {
                $(this).removeClass('f-active');
                femaleLocations.remove($(this).attr('id'))
            } else {
                $(this).addClass('f-active');
                femaleLocations.push($(this).attr('id'))
            }
            $('input[name="locations"]').val(JSON.stringify(femaleLocations));
        } else {
            if ($(this).hasClass('m-active')) {
                $(this).removeClass('m-active');
                maleLocations.remove($(this).attr('id'))
            } else {
                $(this).addClass('m-active');
                maleLocations.push($(this).attr('id'))
            }
            $('input[name="locations"]').val(JSON.stringify(maleLocations));
        }
    });

    $('.upload-button').upload({
        url: 'upload-images',
        uploadButton: '.button',
        //info: Wird bei jedem einzelnen Upload aufgerufen wenn er fertig ist
        onUploadComplete: function (response) {
            attachments.push(response.attachmentID);
            $('input[name="attachments"]').val(JSON.stringify(attachments));
        },
        //info: Wird erst aufgerufen wenn alle Uploads durch sind!
        onUploadsComplete: function (response) {
            $('.button').html('<i class="fal fa-check"></i><span> | </span>mehr Bilder hochladen</br>');
            $('#sendAppointmentRequest').prop('disabled', false).find('i').addClass('fa-paper-plane').removeClass('fa-circle-notch fa-spin');
        },
        beforeUpload: function () {
            $('#sendAppointmentRequest').prop('disabled', true).find('i').removeClass('fa-paper-plane').addClass('fa-circle-notch fa-spin');
        },
        multiple: true
    });

    $('.jsLanguageSwitcher').on('click', function () {
        let button = $(this);
        $.post(BASEURL, {
            action: 'switchLanguage',
            languageCode: button.data('language')
        }, function () {
            location.reload();
        });
    });

    $('#birthday').flatpickr({
        dateFormat: 'd.m.Y',
        disableMobile: 'true'
    });

    let $animationElements = $('.animation-element');
    let $window = $(window);

    function checkIfInView() {
        let windowHeight = $window.height();
        let windowTopPosition = $window.scrollTop();
        let windowBottomPosition = (windowTopPosition + windowHeight);

        $.each($animationElements, function () {
            let $element = $(this);
            let elementHeight = $element.outerHeight();
            let elementTopPosition = $element.offset().top;
            let elementBottomPosition = (elementTopPosition + elementHeight);

            //check to see if this current container is within viewport
            if ((elementBottomPosition >= windowTopPosition) &&
                (elementTopPosition <= windowBottomPosition)) {
                $element.addClass('in-view');
            } else {
                $element.removeClass('in-view');
            }
        });
    }

    $('#data-protection').on('change', function () {
        let today = new Date($.now());
        $('.appointment-content').append(
            $('<input>').prop({
                type: 'hidden',
                name: 'protectionAcceptDate'
            }).val(today.getDate() + '.' + (today.getMonth() + 1 + '.' + today.getFullYear() + ' / ' + today.getHours() + ':' + (today.getMinutes() < 10 ? '0' + (today.getMinutes()) : today.getMinutes())))
        )
    });

    $window.on('scroll resize', checkIfInView);
    $window.trigger('scroll');

    Array.prototype.remove = function () {
        let what, a = arguments, L = a.length, ax;
        while (L && this.length) {
            what = a[--L];
            while ((ax = this.indexOf(what)) !== -1) {
                this.splice(ax, 1);
            }
        }
        return this;
    };

    //Menu
    (function () {

        var Menu = (function () {
            var burger = document.querySelector('.burger');
            var menu = document.querySelector('.menu');
            var menuList = document.querySelector('.menu__list');
            var brand = document.querySelector('.menu__brand');
            var menuItems = document.querySelectorAll('.menu__item');

            var active = false;

            var toggleMenu = function () {
                if (!active) {
                    menu.classList.add('menu--active');
                    menuList.classList.add('menu__list--active');
                    brand.classList.add('menu__brand--active');
                    burger.classList.add('burger--close');
                    for (var i = 0, ii = menuItems.length; i < ii; i++) {
                        menuItems[i].classList.add('menu__item--active');
                    }

                    active = true;
                } else {
                    menu.classList.remove('menu--active');
                    menuList.classList.remove('menu__list--active');
                    brand.classList.remove('menu__brand--active');
                    burger.classList.remove('burger--close');
                    for (var i = 0, ii = menuItems.length; i < ii; i++) {
                        menuItems[i].classList.remove('menu__item--active');
                    }

                    active = false;
                }
            };

            var bindActions = function () {
                burger.addEventListener('click', toggleMenu, false);
            };

            var init = function () {
                bindActions();
            };

            $('.menu__link').on('click', function () {
                menu.classList.remove('menu--active');
                menuList.classList.remove('menu__list--active');
                brand.classList.remove('menu__brand--active');
                burger.classList.remove('burger--close');
                for (var i = 0, ii = menuItems.length; i < ii; i++) {
                    menuItems[i].classList.remove('menu__item--active');
                }

                active = false;
            });

            return {
                init: init
            };

        }());

        Menu.init();

    }());
});
