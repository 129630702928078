require('../vendor/jquery.history.js');

(function ($, window, document, undefined) {
    var Navigation = function (callback) {
        this.init(callback)
    };

    Navigation.prototype = {
        init: function (callback) {
            var self = this;
            var Loading = require('../../../vendor/sogobiz/framework/src/js/modules/Loading');
            self.loading = new Loading();
            self.wrapper = $('#wrapper');
            self.initCutOff();
            self.initHashMapping();
            self.initHistory(callback);
            self.initClickHandlers();
        },

        initCutOff: function () {
            var self = this;
            self.cutOff = (BASEURL.match(/\//g) || []).length - 3;
        },

        initHashMapping: function () {
            var self = this;
            self.hashMapping = {
                '/': 'Home.js',
            };
        },

        initHistory: function (callback) {
            var self = this;
            History.Adapter.bind(window, 'statechange', function () {
                var state = History.getState();
                if (callback != undefined) {
                    callback(state);
                } else {
                    self.handleStateChange(state);
                }
            });
            self.firstTrigger = true;
            History.Adapter.trigger(window, 'statechange');
            window.History = History;
        },

        initClickHandlers: function () {
            $(document).on('click', '.link', function (e) {
                e.preventDefault();
                if (!$(this).hasClass('disabled')) {
                    History.pushState({}, $(document).attr('title'), $(this).attr('href'));
                }
            });
        },

        requireScript: function (script) {
            var self = this;
            if (self.currentScript != script) {
                if (self.currentPage != undefined) {
                    self.currentPage.dispose();
                    delete self.currentPage;
                }
                self.currentScript = script;
                var CurrentPageClass = require('../pages/' + script);
                self.currentPage = new CurrentPageClass();
            }
        },

        loadJavascript: function (state) {
            var self = this;
            var index = state.hash.indexOf('?');
            if (index != -1) {
                state.hash = state.hash.substr(0, index);
            }
            var tmp = state.hash.split('/');
            tmp.shift();
            for (var i = self.cutOff; i > 0; i--) {
                tmp.shift()
            }
            tmp.unshift('');
            state.hash = tmp.join('/');
            var matches = state.hash.match(/(.*)\/[0-9]+/);
            if (matches != null) {
                state.hash = matches[1];
            }
            if (self.hashMapping[state.hash] != undefined) {
                self.requireScript(self.hashMapping[state.hash]);
            } else {
                $.each(self.regExpHashMapping, function (key, script) {
                    var regExp = new RegExp(key, 'i');
                    if (state.hash.match(regExp)) {
                        self.requireScript(script);
                    }
                });
            }
        },

        handleStateChange: function (state) {
            var self = this;
            if (self.firstTrigger || state.data.target == undefined) {
                if (!self.firstTrigger) {
                    self.loading.start();
                    $.post(state.url, {
                        action: 'ajaxPageLoad'
                    }, function (response) {
                        $.response(response, function (response) {
                            self.wrapper.html(response);
                            self.loadJavascript(state);
                            self.loading.stop();
                        });
                    });
                } else {
                    self.loadJavascript(state);
                    self.firstTrigger = false;
                }
            }
        }
    };

    module.exports = Navigation;
})(jQuery, window, document);