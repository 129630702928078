(function ($, undefined) {
    var cache = [];
    $(function () {
        return $.extend({
            trans: function (key) {
                if ($.isArray(key)) {
                    var keys = [];
                    $.each(key, function (index, key) {
                        if (cache[key] == undefined) {
                            keys.push(key);
                        }
                    });
                    if (keys.length > 0) {
                        $.ajax({
                            type: "POST",
                            url: BASEURL,
                            data: {
                                action: 'translateMultiple',
                                keys: keys
                            },
                            async: false,
                            success: function (response) {
                                $.each(response.result, function (key, value) {
                                    cache[key] = value;
                                });
                            }
                        });
                    }
                } else {
                    if (cache[key] == undefined) {
                        cache[key] = $.ajax({
                            type: "GET",
                            url: BASEURL + 'translate/' + key,
                            async: false
                        }).responseText;
                    }
                    return cache[key];
                }
            }
        });
    });
    module.exports = $;
})($);