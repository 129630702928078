(function ($, window, document, undefined) {
    var Page = function () {
        this.init()
    };

    Page.prototype = {
        init: function () {
            var self = this;

            self.initClickHandlers();
        },

        dispose: function () {
            var self = this;

            //$('#selector').off();
        },

        initClickHandlers: function () {
            var self = this;

            /*$('#selector').on('click', function() {
                self.doSomething();
            }*/
        },

        defaultFor: function (parameter, val) {
            return typeof parameter != 'undefined' ? parameter : val;
        }
    };

    module.exports = Page;
})(jQuery, window, document);